<template>
  <div>
    <el-dialog title="提示" :visible.sync="distributionVisible" width="900px" :show-close="false">
      <div slot="title" style="text-align:center;font-weight:700;font-size:16px">分配企业</div>
      <div style="margin-top:15px">
        <div class="selectClass">
          <span>当前用户：张三1</span>
          <el-radio-group v-model="radio">
            <el-radio :label="1">全职工</el-radio>
            <el-radio :label="2">小时工</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="selectClass" v-if="radio==1">
        <div class="sketch_content">
          <p>
            选择企业
            <span style="font-size:12px;color:#aabdd6">( 已分配的企业不可再选 )</span>
          </p>

          <el-checkbox-group v-model="enterpriseDemo" size="mini" style="margin-top:15px">
            <el-checkbox
              style="width:100%"
              v-for="item in enterpriseList"
              :label="item.name"
              :key="item.code"
              :disabled="item.disabled"
            >{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div>
          <div>已分配的企业：</div>
          <div style="margin-top:15px">西安零工保信息科技有限公司 ( 8:30 ~ 12:30 )</div>
          <div>西安零工保信息科技有限公司 ( 14:30 ~ 18:30 )</div>
        </div>
      </div>
      <div class="selectClass" v-if="radio==2">
        <div>
          <p>
            选择企业
            <span style="font-size:12px;color:#aabdd6">( 已分配的企业不可再选 )</span>
          </p>
          <el-select v-model="bankDeposit" size="mini" placeholder="请选择企业" style="margin-top:10px">
            <el-option label="网商银行" value="shanghai"></el-option>
            <el-option label="平安银行" value="beijing"></el-option>
          </el-select>
          <p style="margin-top:15px">
            选择工作时间
            <span style="font-size:12px;color:#aabdd6">( 不超过8小时，不可重叠 )</span>
          </p>
          <el-time-picker
            style="margin-top:10px"
            is-range
            size="mini"
            v-model="value1"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
          ></el-time-picker>
        </div>
        <div>
          <div>已分配的企业：</div>
          <div style="margin-top:15px">西安零工保信息科技有限公司 ( 8:30 ~ 12:30 )</div>
          <div>西安零工保信息科技有限公司 ( 14:30 ~ 18:30 )</div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="distributionVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      bankDeposit: '',
      value1: '',
      radio: 1,
      enterpriseDemo: ['西安零工保信息科技有限公司'],
      enterpriseList: [
        { name: '西安零工保信息科技有限公司', code: 'sad2313as', disabled: true, },
        { name: '西安零工保信息科技有限公司1', code: 'sad2313ass', disabled: false, },
        { name: '西安零工保信息科技有限公司2', code: 'sad231sd3as', disabled: false, },
        { name: '西安零工保信息科技有限公司3', code: 'sad231sd3as', disabled: false },
        { name: '西安零工保信息科技有限公司', code: 'sad2313as', disabled: true, },
        { name: '西安零工保信息科技有限公司1', code: 'sad2313ass', disabled: false, },
        { name: '西安零工保信息科技有限公司2', code: 'sad231sd3as', disabled: false, },
        { name: '西安零工保信息科技有限公司3', code: 'sad231sd3as', disabled: false },
        { name: '西安零工保信息科技有限公司', code: 'sad2313as', disabled: true, },
        { name: '西安零工保信息科技有限公司1', code: 'sad2313ass', disabled: false, },
        { name: '西安零工保信息科技有限公司2', code: 'sad231sd3as', disabled: false, },
        { name: '西安零工保信息科技有限公司3', code: 'sad231sd3as', disabled: false },
        { name: '西安零工保信息科技有限公司', code: 'sad2313as', disabled: true, },
        { name: '西安零工保信息科技有限公司1', code: 'sad2313ass', disabled: false, },
        { name: '西安零工保信息科技有限公司2', code: 'sad231sd3as', disabled: false, },
        { name: '西安零工保信息科技有限公司3', code: 'sad231sd3as', disabled: false },
      ]
    }
  },
  props: {
    distributionVisible: Boolean,
    setCode: String
  },
  mounted () {
    console.log(this.distributionVisible, this.setCode);
  },
  methods: {
    close () {

      this.$emit('update:distributionVisible', false);
      this.$emit('close');
    },
  },
}
</script>
<style lang="scss" scoped>
.selectClass {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.sketch_content {
  // 必须有高度 overflow 为自动
  width: 40%;
  overflow: auto;
  height: 204px;
  border-top: 1px solid #eff1f4;
  border-bottom: 1px solid #eff1f4;
  padding: 10px 0;

  // 滚动条的样式,宽高分别对应横竖滚动条的尺寸
  &::-webkit-scrollbar {
    width: 3px;
  }

  // 滚动条里面默认的小方块,自定义样式
  &::-webkit-scrollbar-thumb {
    background: #8798af;
    border-radius: 2px;
  }

  // 滚动条里面的轨道
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
</style>